import React from 'react'
import AnimatedCards from '../Components/Animcards/AnimatedCards'
import Brand from '../Components/Brand/Brand'


const Home = () => {
  return (
      <AnimatedCards />
  )
}

export default Home